/**
 * Theme global.js
 */

function init() {
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {
        AOS.init();

        $('#hamburger').on('click', function () {
            var nav = $('nav.mobile');
            $(this).toggleClass('open');
            if (nav.hasClass('fadeInLeft')) {
                nav.removeClass('fadeInLeft');
                nav.addClass('fadeOutLeft');
            } else {
                nav.removeClass('fadeOutLeft');
                nav.addClass('fadeInLeft');
                nav.css('right', 'auto');
            }
        });

        $(document).click(function (event) {
            var nav = $('nav.mobile').hasClass('fadeInLeft');

            if (nav === true && $(event.target).attr('id') !== 'hamburger' && !$(event.target).is( "i" ) ) {
                $('#hamburger').click();
            }
        });

        if ($('.mobile .menu-item-has-children').length) {
            $('.mobile .menu-item-has-children > a').append('<i></i>');
        }

        $('.mobile i').on('click', function (e) {
            e.preventDefault();
            if (e.target === $(this)[0]) {
                $(this).toggleClass('open');
                $(this).parent().siblings('.sub-menu').slideToggle(150);
            }
        });

        var location = window.location.href;
        $('.links').find('div').each(function() {
            $(this).toggleClass('active-link', $(this).children().attr('href') == location);
        })
        if($('.active-link').is(':last-child')) {
            $('.links > div:first-child').addClass('next-link');
        } else {
            $('.active-link').next().addClass('next-link');
        }

        $('.menu').find('li').each(function() {
            $(this).toggleClass('active', $(this).children().attr('href') == location);
        })
    });
    $(window).resize(function(){

    });
})(jQuery);
